import { useState, useEffect } from "react";
import { getAssignedVehicle } from "../service/dashboard.service";
import { notification, Table, Button, Form, Modal, } from "antd";
import { driverAcceptTripRequest, driverTripStatus } from "../service/driver.service";
import swal from 'sweetalert'
import { Client } from '@stomp/stompjs';
import useSound from 'use-sound';
import sound from '../assets/audio/audio.mp3';
import {webSocketUrl} from "../service";


const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 19,
        offset: 1,
    },
};

const spanStyleEdit = {
    cursor: "pointer",
    textDecoration: "none",
    color: "#0074D9",
};

const VehicleAssignToDriver = () => {
    const [drivers, setDrivers] = useState([]);
    const [isViewPostModalVisible, setIsViewPostModalVisible] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [playSound] = useSound(sound);

    const [tableCols] = useState([
        {
            key: "no",
            title: "No",
            dataIndex: "title",
            render: (text, post, index) => (
                <span
                    style={spanStyleEdit}
                    onClick={() => {
                        setSelectedVehicle(post);
                        setIsViewPostModalVisible(true);
                    }}
                >
                    {index + 1}. {post.title}
                </span>
            ),
        },
        { key: "brand", title: "Brand", dataIndex: "brand" },
        { key: "registrationNumber", title: "Registration Number", dataIndex: "registrationNumber" },
        {
            key: "evehicleType",
            title: "Vehicle Type",
            dataIndex: "evehicleType",
            render: evehicleType => evehicleType,
            filters: [
                { text: 'Truck', value: 'TRUCK' },
                { text: 'Car', value: 'CAR' },
            ],
            onFilter: (value, record) => record.evehicleType.indexOf(value) === 0,
        },
        { key: "capacity", title: "Capacity", dataIndex: "capacity" },
        {
            key: 'evehicleStatus',
            title: 'Status',
            dataIndex: "evehicleStatus", render: evehicleStatus => evehicleStatus,
            filters: [
                { text: 'Active', value: 'ACTIVE' },
                { text: 'Inactive', value: 'INACTIVE' },
            ],
            onFilter: (value, record) => record.evehicleStatus.indexOf(value) === 0,
        },
        {
            key: 'typeOfGoodsTransported',
            title: 'Type of Goods',
            dataIndex: 'typeOfGoodsTransported',
            render: typeOfGoodsTransported => typeOfGoodsTransported,
            filters: [
                { text: 'general_purpose', value: 'GENERAL_PURPOSE' },
                { text: 'construction', value: 'CONSTRUCTION' },
                { text: 'agriculture', value: 'AGRICULTURE' },
                { text: 'furniture', value: 'FURNITURE' },
                { text: 'electronic', value: 'ELECTRONIC' },
                { text: 'other', value: 'OTHER' },
            ],
            onFilter: (value, record) => record.typeOfGoodsTransported ? record.typeOfGoodsTransported.indexOf(value) === 0 : false,

        },
        {
            key: "action", title: "Action", align: "center",
            render: (vehicle) => [
                <Button
                    onClick={() => {
                        setSelectedVehicle(vehicle);
                        setIsViewPostModalVisible(true);
                    }}
                    style={{ marginRight: "7px", color: "#0074D9" }}
                >
                    View
                </Button>,
                // <Button onClick={() => accept(vehicle)} style={{ marginRight: "7px", color: "#0074D9" }}>
                //     Accept
                // </Button>,
            ],
        },
    ]);

    // const accept = (user) => {
    //     setSelectedVehicle(user);
    //     loadAssignedVehicle();
    //     form?.setFieldsValue({
    //         name: user.firstName + ' ' + user.lastName,
    //     });
    //     setIsAssignModalVisible(true);
    // };

    const loadAssignedVehicle = () => {
        getAssignedVehicle()
            .then((res) => {
                // If res.data is an object
                if (res.data && typeof res.data === 'object') {
                    console.log("Vehicle Assign data", res.data);
                    setDrivers([res.data]);
                } else {
                    setDrivers([]);
                }
            })
            .catch((error) => {
                notification.error({
                    message: "Failed to get Drivers Vehicle Assignments",
                });
            });
    };

    useEffect(() => {
        loadAssignedVehicle();

        const token = localStorage.getItem('token');
        const client = new Client({
            // brokerURL: `${webSocketUrl}/massitec`,
            brokerURL: webSocketUrl,
            connectHeaders: {
                Authorization: `Bearer ${token}`,
            },
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            debug: (str) => {
                console.log("In Debug control", str);
            },

            onConnect: () => {
                console.log('Connected to the broker', client.connected)
                const emailAddress = localStorage.getItem('email');

                client.subscribe(`/topic/customer-trip/${emailAddress}`, message => {
                    const msg = JSON.parse(message.body);
                    console.log('Received message:', JSON.parse(message.body));
                    const customerTripId = msg.id;

                    localStorage.setItem('customerTripId', customerTripId);

                    if (msg.driver === null) {
                        playSound();
                        swal({
                            title: 'New Ride Request!',
                            text: 'You have a new ride request. Please click view for more details.',
                            icon: 'success',
                            buttons: {
                                cancel: 'Cancel',
                                catch: {
                                    text: 'View',
                                    value: 'catch',
                                },
                            },
                        }).then((value) => {
                            switch (value) {
                                case "catch":
                                    const customerName = `${msg.customer.firstName} ${msg.customer.lastName}`;
                                    const customerTelephone = msg.customer.telephone;
                                    const price = msg.price;
                                    let originName = msg.originLocationName.split(',').slice(0, 2).join(',');
                                    let destinationName = msg.destinationLocationName.split(',').slice(0, 2).join(',');

                                    swal({
                                        title: "Ride Details",
                                        text: `Price: ${price}\nOrigin: ${originName}\nDestination: ${destinationName}\nCustomer Name: ${customerName}\nCustomer Telephone: ${customerTelephone}`,
                                        icon: "info",
                                        buttons: {
                                            cancel: 'Close',
                                            catch: {
                                                text: 'Accept Ride',
                                                value: 'accept',
                                            },
                                            reject: {
                                                text: 'Reject Ride',
                                                value: 'reject',
                                            },
                                        },
                                    }).then((value) => {
                                        switch (value) {
                                            case "accept":
                                                const driverEmail = localStorage.getItem('email');
                                                const driverMessage = {
                                                    driver: {
                                                        email: driverEmail,
                                                    },
                                                    status: 'Driver has accepted your ride request',
                                                };
                                                acceptRide();
                                                client.publish({ destination: `/topic/customer-trip/${msg.customer.emailAddress}`, body: JSON.stringify(driverMessage) });

                                                break;
                                            case "reject":
                                                const driverMessageReject = {
                                                    driver: {
                                                        email: driverEmail,
                                                    },
                                                    status: 'Driver has rejected your ride request',
                                                };
                                                unAcceptRide();
                                                client.publish({ destination: `/topic/customer-trip/${msg.customer.emailAddress}`, body: JSON.stringify(driverMessageReject) });

                                                break;
                                            default:
                                                swal({
                                                    title: 'Request Cancelled!',
                                                    text: 'Sorry, no driver is available. Please try again later.',
                                                    icon: 'error',
                                                    confirmButtonText: 'OK',
                                                });
                                        }
                                    });
                                    break;
                                default:
                                    swal({
                                        title: 'Request Cancelled!',
                                        text: 'Sorry, no driver is available. Please try again later.',
                                        icon: 'error',
                                        confirmButtonText: 'OK',
                                    });
                            }
                        });
                    }
                });

            },

            onStompError: (frame) => {
                console.log('Broker reported error: ' + frame.headers['message']);
                console.log('Additional details: ' + frame.body);
                console.log('STOMP error: ', frame);
            },
        });

        client.activate();
    }, []);

    const acceptRide = () => {
        const customerTripId = localStorage.getItem('customerTripId');
        
        driverAcceptTripRequest(customerTripId, { accepted: 'true' })
            .then((res) => {
                if (res && res.status === 200) {
                    upDateDriverTripStatus();
                    notification.success({
                        message: 'Successfully accepted the ride.',
                    });
                    setIsAssignModalVisible(false);
                }
            })
            .catch((error) => {
                notification.error({
                    message: 'Error',
                    description: error.message,
                });
            });
    }

    const unAcceptRide = () => {
        const driverId = localStorage.getItem('userId');
        driverAcceptTripRequest(driverId, { accepted: 'false' })
            .then((res) => {
                if (res && res.status === 200) {
                    notification.success({
                        message: 'You have declined the ride.',
                    });
                    setIsAssignModalVisible(false);
                }
            })
            .catch((error) => {
                notification.error({
                    message: 'Error',
                    description: error.message,
                });
            });
    }

    const upDateDriverTripStatus = () => {
        const driverId = localStorage.getItem('userId');
        driverTripStatus(driverId, { EVehicleTripStatus: 'BOOKED' })
            .then((res) => {
                if (res && res.status === 200) {
                    notification.success({
                        message: 'Driver is on route to the destination.',
                    });
                }
            })
            .catch((error) => {
                notification.error({
                    message: 'Error',
                    description: error && error.message ? error.message : 'Unknown error',
                });
            });
    }

    return (
        <>
            <div>
                {/* modal view  */}
                <Modal
                    open={isViewPostModalVisible}
                    onCancel={() => {
                        setIsViewPostModalVisible(false);
                    }}
                    footer={[
                        <Button
                            onClick={() => {
                                setIsViewPostModalVisible(false);
                            }}
                        >
                            Cancel
                        </Button>,
                        // <Button
                        //     type="primary"
                        //     onClick={() => {
                        //         setIsViewPostModalVisible(false);
                        //         setIsAssignModalVisible(true);
                        //     }}
                        // >
                        //     Assign
                        // </Button>,

                    ]}
                >
                    <Form
                        {...layout}
                        name="nest-messages"
                        form={form}
                        style={{ paddingTop: "35px" }}
                    >
                        <Form.Item key={'brand'} name={"brand"} label="Brand">
                            <p style={{ margin: 0 }}>{selectedVehicle?.brand}</p>
                        </Form.Item>
                        <Form.Item key={'color'} name={"color"} label="Color">
                            <p style={{ margin: 0 }}>{selectedVehicle?.color}</p>
                        </Form.Item>

                        <Form.Item key={'registrationNumber'} name={"registrationNumber"} label="Number Plate">
                            <p style={{ margin: 0 }}>{selectedVehicle?.registrationNumber}</p>
                        </Form.Item>

                        <Form.Item key={'capacity'} name={"capacity"} label="Capacity">
                            <p style={{ margin: 0 }}>{selectedVehicle?.capacity}</p>
                        </Form.Item>
                        <Form.Item key={'evehicleType'} name={"evehicleType"} label="Vehicle Type">
                            <p style={{ margin: 0 }}>{selectedVehicle?.evehicleType}</p>
                        </Form.Item>
                        <Form.Item key={'evehicleStatus'} name={"evehicleStatus"} label="Status">
                            <p style={{ margin: 0 }}>{selectedVehicle?.evehicleStatus}</p>
                        </Form.Item>
                        <Form.Item key={'typeOfGoodsTransported'} name={"typeOfGoodsTransported"} label="Type of Goods">
                            <p style={{ margin: 0 }}>{selectedVehicle?.typeOfGoodsTransported}</p>
                        </Form.Item>
                    </Form>
                </Modal>

                {/* modals accept */}
                {/* <Modal
                    open={isAssignModalVisible}
                    onCancel={() => setIsAssignModalVisible(false)}
                    footer={[
                        <Button onClick={() => setIsAssignModalVisible(false)}>Cancel</Button>,
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => acceptRide()}
                        >
                            Accept
                        </Button>,
                        <Button
                            type="primary"
                            danger
                            htmlType="submit"
                            onClick={() => {
                                Modal.confirm({
                                    title: 'Are you sure you want to unassign?',
                                    onOk: () => unAcceptRide(),
                                    onCancel: () => { },
                                });
                            }}
                        >
                            Decline
                        </Button>
                    ]}
                >
                    <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <WarningFilled style={{ fontSize: '24px', color: '#FFCC00', marginRight: '0.5rem' }} />
                            <h2>Confirm!</h2>
                        </div>
                        <Typography.Title level={5}>
                            A Customer Request for a Ride Are you available!
                        </Typography.Title>
                    </div>
                </Modal> */}

                {/* main content */}
                <Table columns={tableCols} dataSource={drivers} />
            </div >
        </>
    )
};

export default VehicleAssignToDriver;
