import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './react-leaflet-geosearch.css';
import { useNavigate } from 'react-router-dom';
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { customerVehicleRequest, customerVehicleRequestAccept } from '../service/customer.service';
import { Modal } from '@mui/material';
import Avatar from "@mui/material/Avatar";
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import swal from 'sweetalert'
import { Client } from '@stomp/stompjs';
import {webSocketUrl} from "../service";


let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const defaultTheme = createTheme();

function LocationMarker({ onLocationChange, onLocationNameChange }) {
  const [originCoordinate, setOriginCoordinate] = useState(null);
  const [originLocationName, setOriginLocationName] = useState(null);
  const map = useMapEvents({
    locationfound(e) {
      setOriginCoordinate(e.latlng);
      onLocationChange(e.latlng, 'Origin');
      map.flyTo(e.latlng, map.getZoom());
    
      // Reverse geocoding
      fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${e.latlng.lat}&lon=${e.latlng.lng}`)
        .then(response => response.json())
        .then(data => {
          setOriginLocationName(data.display_name);
          onLocationNameChange(data.display_name, 'Origin')
          console.log("User Location Name is: ", data.display_name);
        })
        .catch(error => console.error(error));
    }
  });

  // Use useEffect to initiate location lookup on component mount
  useEffect(() => {
    map.locate();
  }, [map]);

  return originCoordinate === null ? null : (
    <Marker position={originCoordinate} icon={DefaultIcon}>
      <Popup>You are here</Popup>
    </Marker>
  );
}

const SearchControl = ({ onLocationChangeCoordinate, onLocationSearch, ...props }) => {
  const [destinationCoordinate, setDestinationCoordinate] = useState('');
  const [destinationName, setDestinationName] = useState('');
  const map = useMap()
  const [open, setOpen] = useState(false);


  useEffect(() => {
    const searchControl = new GeoSearchControl({
      provider: props.provider,
      ...props,
    })

    const handleShowLocation = (data) => {
      const lat = data.location.x;
      const lng = data.location.y;
      const locationName = data.location.label;

      setDestinationCoordinate({ lat, lng });
      setDestinationName(locationName);

      //Call onLocationChange when the location is found
      onLocationChangeCoordinate(lat, lng, locationName);
      onLocationSearch(true);
      setOpen(true);
    };

    map.on('geosearch/showlocation', handleShowLocation);
    map.addControl(searchControl)
    return () => map.removeControl(searchControl)
  }, [props])

  return null
}

var provider = new OpenStreetMapProvider();


const CustomerRequest = () => {
  const [destinationCoordinate, setDestinationCoordinate] = useState('');
  const [originCoordinate, setOriginCoordinate] = useState('');
  const [destinationLocationName, setDestinationLocationName] = useState('');
  const [originLocationName, setOriginLocationName] = useState('');
  const [EVehicleType, setEVehicleType] = useState('');
  const [EGoodsType, setEGoodsType] = useState('');
  const [capacity, setCapacity] = useState('');
  const [accepted] = useState(true);
  const [userConfirmed, setUserConfirmed] = useState(false);
  const [ETypeOfRide, setETypeOfRide] = useState('');

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOriginLocationChange = (coordinate) => {
    const latLngString = `${coordinate.lat},${coordinate.lng}`;
    setOriginCoordinate(latLngString);
  };

  const handleDestinationLocationChange = (lat, lng, locationName) => {
    const latLngString = `${lat},${lng}`;
    setDestinationCoordinate(latLngString);
    setDestinationLocationName(locationName);
  };

  const handleOriginLocationNameChange = (name, type) => {
    if (type === 'Origin') {
      setOriginLocationName(name);
    }
  };

  const handleOnLocationSearch = (locationFound) => {
    setOpen(locationFound);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    customerVehicleRequest({
      destinationCoordinate,
      originCoordinate,
      destinationLocationName,
      originLocationName,
      EVehicleType,
      EGoodsType,
      ETypeOfRide,
      capacity
    })
      .then((res) => {
        console.log('New Request data being sent :', res.data);
        const { price, distanceInMetres } = res.data;
        const totalPrice = res.data.price;
        const distance = res.data.distanceInMetres;

        switch (res.data.status.toLowerCase()) {
          case "success":
            localStorage.setItem('request', JSON.stringify(res.data));
            swal({
              title: "Confirm your request!",
              content: {
                element: "div",
                attributes: {
                  innerHTML: `You are about to request a ride<br/><b>Price: GHS ${totalPrice}</b><br/><b>Distance: ${distance} metres</b>`
                },
              },
              icon: "warning",
              buttons: {
                cancel: true,
                confirm: {
                  text: "Confirm",
                  value: true,
                  visible: true,
                  closeModal: true
                }
              },
              successMode: true,
            })
              .then(confirmRequest => {
                if (confirmRequest) {
                  swal("Confirmed!", "Your request has been confirmed successfully.", "success")
                    .then(() => {
                      handleUserConfirmation({
                        price,
                        distanceInMetres,
                        destinationCoordinate,
                        originCoordinate,
                        destinationLocationName,
                        originLocationName,
                        EVehicleType,
                        EGoodsType,
                        ETypeOfRide,
                        capacity,
                        accepted,
                      });
                      setUserConfirmed(true);
                    });
                  handleClose()
                }
              });
            break;
          case "failed":
            swal("Failed!", "Your request could not be sent. Please try again.", "error");
            break;
          default:
            swal("Error!", "An unexpected error occurred. Please try again.", "error");
        }
      })
      .catch((error) => {
        swal("Error!", "Failed to send request to the driver.", "error");
      });
  };

  const handleUserConfirmation = (data) => {
    customerVehicleRequestAccept(data)
      .then((res) => {
        navigate("/customer-dashboard", { replace: true });
        localStorage.removeItem('request');
      })
      .catch((error) => {
        swal("Error!", "Failed to confirm request. Please try again.", "error");
      });
  };

  useEffect(() => {
    if (!userConfirmed) return;
    const token = localStorage.getItem('token');

    const client = new Client({
      // brokerURL: `${webSocketUrl}/massitec`,
      brokerURL: webSocketUrl,
      connectHeaders: {
        Authorization: `Bearer ${token}`,
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      debug: (str) => {
        console.log("In Debug control", str);
      },

      onConnect: () => {
        console.log('Connected to the broker', client.connected)
        const emailAddress = localStorage.getItem('email');

        client.subscribe(`/topic/customer-trip/${emailAddress}`, message => {
          const msg = JSON.parse(message.body);
          console.log('Received message from driver:', msg);

          if (msg.status === 'Driver has accepted your ride request') {
            swal({
              title: 'Driver Found!',
              text: 'A driver has accepted your request. They are on their way to come.',
              icon: 'success',
              confirmButtonText: 'OK',
            });
            // Update the dashboard with the driver's details

          } else if (msg.status === 'No driver found for your request') {
            swal({
              title: 'No Driver Found!',
              text: 'Sorry, no driver is available. Please try again later.',
              icon: 'error',
              confirmButtonText: 'OK',
            });
          }
          else if (msg.status === 'Driver has rejected your ride request') {
            swal({
              title: 'Ride Rejected!',
              text: 'Sorry, driver has rejected your request. Please try again later.',
              icon: 'error',
              confirmButtonText: 'OK',
            });
          }
        });

      },

      onStompError: (frame) => {
        console.log('Broker reported error: ' + frame.headers['message']);
        console.log('Additional details: ' + frame.body);
        console.log('STOMP error: ', frame);
      },
    });

    client.activate();

  }, [userConfirmed]);

  return (
    <div>
      <MapContainer
        center={{ lat: 51.505, lng: -0.09 }}
        zoom={13}
        style={{ height: '500px', width: '100%' }}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LocationMarker onLocationChange={handleOriginLocationChange} onLocationNameChange={handleOriginLocationNameChange} />
        <SearchControl
          provider={provider}
          onLocationChangeCoordinate={handleDestinationLocationChange}
          onLocationSearch={handleOnLocationSearch}
          showMarker={true}
          showPopup={false}
          popupFormat={({ query, result }) => result.label}
          resultFormat={({ result }) => result.label}
          maxMarkers={3}
          retainZoomLevel={false}
          animateZoom={true}
          autoClose={true}
          searchLabel={"Enter address, please"}
          keepResult={true}
          marker={DefaultIcon}
          updateMap={true}
          autoComplete={true}
          onSearchCompleted={() => setOpen(true)}
        />
      </MapContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0 auto',
          backdropFilter: 'blur(5px)',
          width: '100%',
          height: '62%',
          overflow: 'auto',
          color: '#fff'
        }}
      >
        <ThemeProvider theme={defaultTheme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Request a Ride
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleFormSubmit}
                sx={{ mt: 5 }}
              >
                <Grid container spacing={2.5}>
                  {/* <Grid item xs={12} sx={{
                    width: 500,
                    maxWidth: '100%',
                  }}>

                    <TextField
                      autoComplete="origin"
                      name="origin"
                      required
                      fullWidth
                      id="origin"
                      label="Origin Name"
                      autoFocus
                      size="small"
                      onChange={(event) => setOriginLocationName(event.target.value)}
                      sx={{
                        '& label': {
                          color: '#4CAF50',
                        },
                        '& label.Mui-focused': {
                          color: 'white',
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: 'yellow',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#4CAF50',
                          },
                          '&:hover fieldset': {
                            borderColor: '#4CAF50',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#4CAF50',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: 'white',
                        },
                      }}
                    />
                  </Grid> */}
                  <Grid item xs={6}>
                    <TextField
                      required
                      fullWidth
                      id="capacity"
                      label="Capacity"
                      name="capacity"
                      autoComplete="email"
                      size="small"
                      onChange={(event) => setCapacity(event.target.value)}
                      sx={{
                        '& label': {
                          color: '#4CAF50',
                        },
                        '& label.Mui-focused': {
                          color: 'white',
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: 'yellow',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#4CAF50',
                          },
                          '&:hover fieldset': {
                            borderColor: '#4CAF50',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#4CAF50',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: 'white',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      sx={{
                        width: 500,
                        maxWidth: '100%',
                        '& .MuiInputLabel-root': {
                          color: '#4CAF50',
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: 'white',
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: 'yellow',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#4CAF50',
                          },
                          '&:hover fieldset': {
                            borderColor: '#4CAF50',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#4CAF50',
                          },
                        },
                        '& .MuiSelect-root': {
                          color: '#4CAF50',
                        },
                        '& .MuiSelect-icon': {
                          color: '#4CAF50',
                        },
                      }}
                      size="small"
                    >
                      <InputLabel id="demo-select-small-label">
                        Type of Ride
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        required
                        value={ETypeOfRide}
                        label="Type of Ride"
                        sx={{ textAlign: "left" }}
                        onChange={(event) => setETypeOfRide(event.target.value)}
                      >
                        <MenuItem value={'PASSENGER_RIDE'}>Passenger Ride </MenuItem>
                        <MenuItem value={'GOODS_RIDE'}>Goods Ride</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item spacing={1.5} xs={12} sm={6}>
                    <FormControl
                      sx={{
                        width: 500,
                        maxWidth: '100%',
                        '& .MuiInputLabel-root': {
                          color: '#4CAF50',
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: 'white',
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: 'yellow',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#4CAF50',
                          },
                          '&:hover fieldset': {
                            borderColor: '#4CAF50',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#4CAF50',
                          },
                        },
                        '& .MuiSelect-root': {
                          color: '#4CAF50',
                        },
                        '& .MuiSelect-icon': {
                          color: '#4CAF50',
                        },
                        '& .MuiMenuItem-root.Mui-selected': {
                          color: 'white',
                        },
                      }}
                      size="small"
                    >
                      <InputLabel id="demo-select-small-label">
                        Vehicle Type
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        required
                        value={EVehicleType}
                        label="Vehicle Type"
                        sx={{ textAlign: "left" }}
                        onChange={(event) => setEVehicleType(event.target.value)}
                      >
                        <MenuItem value={'TRUCK'}>Truck</MenuItem>
                        <MenuItem value={'CAR'}>Car</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item spacing={1.5} xs={12} sm={6}>
                    <FormControl
                      sx={{
                        width: 500,
                        maxWidth: '100%',
                        color: '#fff',
                        '& .MuiInputLabel-root': {
                          color: '#4CAF50',
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: 'white',
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: 'yellow',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#4CAF50',
                          },
                          '&:hover fieldset': {
                            borderColor: '#4CAF50',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#4CAF50',
                          },
                        },
                        '& .MuiSelect-root': {
                          color: '#4CAF50',
                        },
                        '& .MuiSelect-icon': {
                          color: '#4CAF50',
                        },
                      }}
                      size="small"
                    >
                      <InputLabel id="demo-select-small-label">
                        Type of Goods
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        required
                        value={EGoodsType}
                        label="Goods Type"
                        sx={{ textAlign: "left" }}
                        onChange={(event) => setEGoodsType(event.target.value)}
                      >
                        <MenuItem value={'GENERAL_PURPOSE'}>General Purpose</MenuItem>
                        <MenuItem value={'CONSTRUCTION'}>Construction</MenuItem>
                        <MenuItem value={'AGRICULTURE'}>Agriculture </MenuItem>
                        <MenuItem value={'FURNITURE'}>Furniture</MenuItem>
                        <MenuItem value={'ELECTRONIC'}>Electronic</MenuItem>
                        <MenuItem value={'OTHER'}>Other</MenuItem>
                      </Select>
                      <style>
                        {`
                            .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
                              color: #fff;
                          }
                        `}
                      </style>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                  <Button
                    type="cancel"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, width: "30%" }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, width: "30%" }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Box>
            </Box>
            {/* <Copyright sx={{ mt: 5 }} /> */}
          </Container>
        </ThemeProvider>
      </Modal>
    </div>
  )
};

export default CustomerRequest;
