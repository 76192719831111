import axios from "./index";
import { notification } from "antd";

export function userOnboarding(
  id,
  { email = "", EUserType = "", ERole = "" } = {}
) {
  if (email === "" && EUserType === "" && ERole === "") {
    return Promise.reject("Missing required param(s): email, EUserType, ERole");
  }
  return axios.post(`/corporate/${id}/users`, {
    emailAddress: email,
    userType: EUserType,
    role: ERole,
  });
}

export function vehicleOnboarding({
  brand = "",
  color = "",
  capacity = "",
  registrationNumber = "",
  EVehicleType = "",
  EVehicleStatus = "",
  EGoodsType = "",
} = {}) {
  if (
    brand === "" &&
    color === "" &&
    capacity === "" &&
    registrationNumber === ""
  ) {
    return Promise.reject(
      "Missing required param(s): name, color, capacity, registrationNumber"
    );
  }
  return axios.post("/vehicle", {
    brand: brand,
    color: color,
    capacity: capacity,
    registrationNumber: registrationNumber,
    type: EVehicleType,
    status: EVehicleStatus,
    typeOfGoodsTransported: EGoodsType,
  });
}

export function updateVehicle(
  vehicleId,
  {
    brand = "",
    color = "",
    capacity = "",
    registrationNumber = "",
    type = "",
    status = "",
    typeOfGoodsTransported = "",
  } = {}
) {
  if (
    brand === "" &&
    color === "" &&
    capacity === "" &&
    registrationNumber === ""
  ) {
    return Promise.reject(
      "Missing required param(s): brand, color, capacity, registrationNumber"
    );
  }
  return axios.put(`/vehicle/${vehicleId}`, {
    brand: brand,
    color: color,
    capacity: capacity,
    registrationNumber: registrationNumber,
    type: type,
    status: status,
    typeOfGoodsTransported: typeOfGoodsTransported,
  });
}

export function deleteVehicle(vehicleId) {
  return axios.delete(`/vehicle/${vehicleId}`);
}

export function getOnboardedVehicles() {
  return axios.get("/vehicle/all");
}

export function getAllUsersInCorporate() {
  return axios.get("/corporate/{id}/users");
}

export function assignVehicleToDriverUser(
  vehicleId,
  { driverOrIndividualUserEmail = "" } = {}
) {
  if (driverOrIndividualUserEmail === "") {
    return Promise.reject(
      notification.error({
        message: "Missing required param(s): driverOrIndividualUserEmail",
      })
    );
  }
  return axios.post(`/vehicle/${vehicleId}/assign-driver`, {
    driverOrIndividualUserEmail: driverOrIndividualUserEmail,
  });
}

export function unAssignVehicleToDriverUser(vehicleId) {
  return axios.post(`/vehicle/${vehicleId}/unassign-driver`);
}
