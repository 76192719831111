import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import LoginBanner from "../../assets/login-banner.gif";
import { login } from "../../service/maxon.service";
import { notification } from "antd";
import { IconButton, InputAdornment } from "@mui/material";
import swal from 'sweetalert'
import { Client } from '@stomp/stompjs';
import {webSocketUrl} from "../../service";


const Copyright = (props) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href='https://massitec.com/' target='_blank'>
        Massitec Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const Login = () => {
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [accepted] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const defaultTheme = createTheme();

  const loginUser = (event) => {
    event.preventDefault()
    login({ emailAddress, password })
      .then((res) => {
        const token = res.data.token;
        const userId = res.data.data.id;
        const userType = res.data.data.euserType;
        const verifiedAccound = res.data.data.corporate;
        const email = res.data.data.emailAddress;
        const username = res.data.data.firstName;
        setIsLoggedIn(true);

        if (userType === 'CORPORATE' && !verifiedAccound) {
          notification.error({
            message: 'Your account is corporate please create a corporate account first.',
          })
          navigate('/create-corporate');
          return;
        }

        localStorage.setItem('token', token);
        localStorage.setItem('userId', userId);
        localStorage.setItem("email", email);
        localStorage.setItem("username", username);
        localStorage.setItem('userType', userType);

        switch (userType) {
          case 'CORPORATE':
            const corporateId = res.data.data.corporate.id;
            localStorage.setItem('corporateId', corporateId);
            navigate('/corporate-dashboard');
            break;
          case 'INDIVIDUAL':
            navigate('/individual-dashboard');
            break;
          case 'CUSTOMER':
            navigate('/customer-dashboard');
            break;
          case 'DRIVER':
            navigate('/driver-dashboard');
            break;
          default:
            navigate('/dashboard');
        }

        const client = new Client({
          // brokerURL: `${webSocketUrl}/massitec`,
          brokerURL: webSocketUrl,
          connectHeaders: {
            Authorization: `Bearer ${token}`,
          },
          reconnectDelay: 5000,
          heartbeatIncoming: 4000,
          debug: (str) => {
            console.log("In Debug control", str);
          },

          onConnect: () => {
            console.log('Connected to the broker', client.connected)
            const emailAddress = localStorage.getItem('email');

            client.subscribe(`/topic/customer-trip/${emailAddress}`, message => {
              const msg = JSON.parse(message.body);
              console.log('Received message:', JSON.parse(message.body));

              if (msg.message === 'Finding driver for you, you will be notified when a driver accepts your request') {
                swal({
                  title: 'Driver Found!',
                  text: 'A driver has accepted your request. Please check your dashboard for more details.',
                  icon: 'success',
                  confirmButtonText: 'Accept',
                });
              } else if (msg.message === 'No driver found for your request') {
                swal({
                  title: 'No Driver Found!',
                  text: 'Sorry, no driver is available. Please try again later.',
                  icon: 'error',
                  confirmButtonText: 'OK',
                });
              }
            });
          },

          onStompError: (frame) => {
            console.log('Broker reported error: ' + frame.headers['message']);
            console.log('Additional details: ' + frame.body);
            console.log('STOMP error: ', frame);
          },
        });

        client.activate();
      })
      .catch((error) => {
        notification.error({
          message: 'Username or password missmatch',
        })
      })
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item xs={false} sm={4} md={7}
          sx={{
            backgroundImage: `url(${LoginBanner})`,
            backgroundRepeat: "no-repeat",
            width: "70%",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "100%",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: '#fff',
              boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
              padding: 2,
              borderRadius: 1,
              width: '30rem',
              height: '32rem',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={loginUser} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(event) => setEmailAddress(event.target.value)}
                sx={{
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.02)',
                    borderColor: '#6CB4EE',
                  },
                  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#6CB4EE',
                  },
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label='Password'
                variant="outlined"
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                onChange={(event) => setPassword(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}

                sx={{
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.02)',
                    borderColor: '#6CB4EE',
                  },
                  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#6CB4EE',
                  },
                }}
              />

              <Grid container alignItems="flex-start">
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.02)',
                    backgroundColor: 'theme.palette.primary.main',
                  },
                }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/reset-link" variant="body2">Forgot password?</Link>
                </Grid>
                <Grid item>
                  <Link href="/register" variant="body2" sx={{ mr: 4 }}>
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Login;
