import { useState, useEffect } from "react";
import { notification, Table, Button, Select, Form, Modal, Popconfirm, Typography, Tooltip, Input, } from "antd";
import { getUserOnboardingHistory, getVehicleLocation, getVehicleOnboardingHistory, getVehiclesAssignedToDriver, updateVehicleLocation } from "../service/dashboard.service";
import { assignVehicleToDriverUser, deleteVehicle, unAssignVehicleToDriverUser, updateVehicle } from '../service/onboarding.service';
import swal from 'sweetalert'


const { Option } = Select;

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 19,
        offset: 1,
    },
};

const spanStyleEdit = {
    cursor: "pointer",
    textDecoration: "none",
    color: "#0074D9",
};

const HistoryVehicleOnboarding = () => {
    const [users, setUsers] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [isUpdatePostModalVisible, setIsUpdatePostModalVisible] = useState(false);
    const [isViewPostModalVisible, setIsViewPostModalVisible] = useState(false);
    const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);
    const [isLocateModalVisible, setIsLocateModalVisible] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [form] = Form.useForm();
    const [driverOrIndividualUserEmail, setDriverOrIndividualUserEmail] = useState('');
    const [location, setLocation] = useState('');
    const [tableCols] = useState([
        {
            key: "no",
            title: "No",
            dataIndex: "title",
            render: (text, post, index) => (
                <span
                    style={spanStyleEdit}
                    onClick={() => {
                        setSelectedVehicle(post);
                        setIsViewPostModalVisible(true);
                    }}
                >
                    {index + 1}. {post.title}
                </span>
            ),
        },
        { key: "brand", title: "Brand", dataIndex: "brand" },
        { key: "registrationNumber", title: "Registration Number", dataIndex: "registrationNumber" },
        {
            key: "evehicleType",
            title: "Vehicle Type",
            dataIndex: "evehicleType",
            render: evehicleType => evehicleType,
            filters: [
                { text: 'Truck', value: 'TRUCK' },
                { text: 'Car', value: 'CAR' },
            ],
            onFilter: (value, record) => record.evehicleType.indexOf(value) === 0,
        },
        { key: "capacity", title: "Capacity", dataIndex: "capacity" },
        {
            key: 'evehicleStatus',
            title: 'Status',
            dataIndex: "evehicleStatus", render: evehicleStatus => evehicleStatus,
            filters: [
                { text: 'Active', value: 'ACTIVE' },
                { text: 'Inactive', value: 'INACTIVE' },
            ],
            onFilter: (value, record) => record.evehicleStatus.indexOf(value) === 0,
        },
        {
            key: 'typeOfGoodsTransported',
            title: 'Type of Goods',
            dataIndex: 'typeOfGoodsTransported',
            render: typeOfGoodsTransported => typeOfGoodsTransported,
            filters: [
                { text: 'general_purpose', value: 'GENERAL_PURPOSE' },
                { text: 'construction', value: 'CONSTRUCTION' },
                { text: 'agriculture', value: 'AGRICULTURE' },
                { text: 'furniture', value: 'FURNITURE' },
                { text: 'electronic', value: 'ELECTRONIC' },
                { text: 'other', value: 'OTHER' },
            ],
            onFilter: (value, record) => record.typeOfGoodsTransported ? record.typeOfGoodsTransported.indexOf(value) === 0 : false,

        },
        {
            key: "action", title: "Action", align: "center",
            render: (vehicle) => [
                <Button onClick={() => editVehicle(vehicle)} style={{ marginRight: "7px", color: "#0074D9" }}>
                    Edit
                </Button>,
                vehicle.driverUser === null ? (
                    <Button onClick={() => assign(vehicle)} style={{ marginRight: "7px", color: "#0074D9" }}>
                        Assign
                    </Button>
                ) : (
                    <Button onClick={() => unassign(vehicle)} style={{ marginRight: "7px", color: "#0074D9" }}>
                        Unassign
                    </Button>
                ),
                <Button
                    onClick={() => {
                        setSelectedVehicle(vehicle);
                        setIsViewPostModalVisible(true);
                    }}
                    style={{ marginRight: "7px", color: "#0074D9" }}
                >
                    View
                </Button>,
                <Popconfirm
                    title="Are you sure to delete this vehicle?"
                    onConfirm={() => handleVehicleDelete(vehicle.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button style={{ marginRight: "7px", color: "#0074D9" }}>Delete</Button>
                </Popconfirm>,
            ],
        },
    ]);

    const loadVehicles = () => {
        getVehicleOnboardingHistory()
            .then((res) => {
                setVehicles(res.data);
            })
            .catch((error) => {
                notification.error({
                    message: "Failed to get posts",
                });
            });
    };

    const loadUsers = () => {
        getUserOnboardingHistory()
            .then((res) => {
                setUsers(res.data);
            })
            .catch((error) => {
                notification.error({
                    message: "Failed to get posts",
                });
            });
    };

    useEffect(() => {
        loadVehicles();
    }, []);

    const editVehicle = (vehicle) => {
        if (!vehicle) {
            console.error('Vehicle is undefined');
            return;
        }

        setSelectedVehicle(vehicle);
        form?.setFieldsValue({
            brand: vehicle.brand,
            registrationNumber: vehicle.registrationNumber,
            capacity: vehicle.capacity,
            color: vehicle.color,
            evehicleType: vehicle.evehicleType,
            evehicleStatus: vehicle.evehicleStatus,
            typeOfGoodsTransported: vehicle.typeOfGoodsTransported,
        });
        setIsUpdatePostModalVisible(true);
    };

    const assign = (user) => {
        setSelectedVehicle(user);
        loadUsers();
        form?.setFieldsValue({
            name: user.firstName + ' ' + user.lastName,
        });
        setIsAssignModalVisible(true);
    };

    const unassign = (vehicle) => {
        setSelectedVehicle(vehicle);
        setIsAssignModalVisible(true);
    };

    //Verify this with Ema how to get the current location of the vehicle
    const getTheCurrentLocationOfTheVehicle = (vehicle) => {
        if (!vehicle) {
            console.error('Vehicle is undefined');
            return;
        }
        setSelectedVehicle(vehicle)
        getVehicleLocation(selectedVehicle.id)
            .then((res) => {
                swal({
                    title: "Vehicle Location",
                    content: {
                        element: "div",
                        attributes: {
                            innerHTML: `Latitude: ${res.data.latitude}<br/>Longitude: ${res.data.longitude}`
                        },
                    },
                    icon: "success",
                    button: "Ok",
                });
            })
            .catch((error) => {
                notification.error({
                    message: "Failed to retrieve vehicle location",
                });
            });
    };

    const updateCurrentVehicleLocation = () => {
        if (!selectedVehicle) {
            console.error('Vehicle is undefined');
            return;
        }

        updateVehicleLocation(selectedVehicle.id, {
            location: location,
        })
            .then((res) => {
                notification.success({
                    message: "Vehicle location successfully updated",
                });
                setIsLocateModalVisible(false);
            })
            .catch((error) => {
                notification.error({
                    message: "Failed to update vehicle location",
                });
            });
    };

    const assignVehicle = () => {
        isDriverAssignedVehicle(driverOrIndividualUserEmail)
            .then((isAssigned) => {
                if (isAssigned) {
                    swal({
                        title: "Sorry! Driver is already assigned to a vehicle",
                        icon: "error",
                        button: "Ok",
                    });

                } else {
                    assignVehicleToDriverUser(selectedVehicle.id, {
                        driverOrIndividualUserEmail: driverOrIndividualUserEmail,
                    })
                        .then(() => {
                            notification.success({
                                message: "Vehicle successfully assigned",
                            });
                            setIsAssignModalVisible(false);
                            setSelectedVehicle(prevState => ({
                                ...prevState,
                                driverUser: driverOrIndividualUserEmail
                            }));
                            loadVehicles();
                        }).catch((error) => {
                            notification.error({
                                message: "Failed to assign vehicle",
                            });
                        })
                }
            }).catch((error) => {
                notification.error({
                    message: "Failed to check driver assignment",
                });
            });
    };

    const isDriverAssignedVehicle = (email) => {
        return getVehiclesAssignedToDriver({ emailAddress: email })
            .then((res) => {
                return res.data !== null && res.data.length !== 0;
            }).catch((error) => {
                console.error("Failed to check driver assignment", error);
                return false;
            });
    };

    const unAssignVehicle = () => {
        unAssignVehicleToDriverUser(selectedVehicle.id)
            .then(() => {
                notification.success({
                    message: "Vehicle successfully unassigned",
                });
                setIsAssignModalVisible(false);
                setSelectedVehicle(prevState => ({
                    ...prevState,
                    driverUser: null
                }));
                loadVehicles();
            }).catch((error) => {
                notification.error({
                    message: "Failed to unassign vehicle",
                });
            })
    };

    const onEditPostSuccess = () => {
        loadVehicles();
        setSelectedVehicle(null);
        setIsUpdatePostModalVisible(false);
    };

    const handleVehicleDelete = (vehicleId) => {
        deleteVehicle(vehicleId)
            .then(() => {
                notification.success({
                    message: "Vehicle successfully deleted",
                });
                loadVehicles();
            }).catch((error) => {
                notification.error({
                    message: "Failed to delete vehicle",
                });
            })
    }

    const vehcleUpdate = () => {
        form
            .validateFields()
            .then((values) => {
                updateVehicle(selectedVehicle.id, {
                    brand: selectedVehicle.brand,
                    color: selectedVehicle.color,
                    capacity: selectedVehicle.capacity,
                    registrationNumber: selectedVehicle.registrationNumber,
                    type: selectedVehicle.evehicleType,
                    status: selectedVehicle.evehicleStatus,
                    typeOfGoodsTransported: selectedVehicle.typeOfGoodsTransported,
                })
                    .then((res) => {
                        notification.success({
                            message: "Vehicle details successfully updated",
                        });
                        onEditPostSuccess();
                    })
                    .catch((error) => {
                        notification.error({
                            message: "Failed to update Vehicle details",
                        });
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const onUpdatePostCancel = () => {
        setIsUpdatePostModalVisible(false);
    };

    const onLocationClose = () => {
        setIsLocateModalVisible(false);
    };

    return (
        <>
            <div>
                {/* modals edit */}
                <Modal
                    open={isUpdatePostModalVisible}
                    onCancel={onUpdatePostCancel}
                    footer={[
                        <Button onClick={onUpdatePostCancel}>Cancel</Button>,
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => vehcleUpdate()}
                        >
                            Update Vehicle
                        </Button>,
                    ]}
                >
                    <Form
                        {...layout}
                        name="nest-messages"
                        form={form}
                        style={{ paddingTop: "35px" }}
                    >
                        <Form.Item
                            name={"brand"}
                            label="Name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input the name!",
                                },
                            ]}
                        >
                            <Input
                                value={selectedVehicle?.brand}
                                onChange={(e) => {
                                    setSelectedVehicle({
                                        ...selectedVehicle,
                                        brand: e.target.value,
                                    });
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name={"registrationNumber"}
                            label="Number Plate"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input the number plate!",
                                },
                            ]}
                        >
                            <Input
                                value={selectedVehicle?.registrationNumber}
                                onChange={(e) => {
                                    setSelectedVehicle({
                                        ...selectedVehicle,
                                        registrationNumber: e.target.value,
                                    });
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name={"capacity"}
                            label="Capacity"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input the capacity!",
                                },
                            ]}
                        >
                            <Input
                                value={selectedVehicle?.capacity}
                                onChange={(e) => {
                                    setSelectedVehicle({
                                        ...selectedVehicle,
                                        capacity: e.target.value,
                                    });
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name={"color"}
                            label="Color"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input the color!",
                                },
                            ]}
                        >
                            <Input
                                value={selectedVehicle?.color}
                                onChange={(e) => {
                                    setSelectedVehicle({
                                        ...selectedVehicle,
                                        color: e.target.value,
                                    });
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name={"evehicleType"}
                            label="Vehicle Type"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input the vehicle type!",
                                },
                            ]}
                        >
                            <Select
                                value={selectedVehicle?.evehicleType}
                                onChange={(e) => {
                                    setSelectedVehicle({
                                        ...selectedVehicle,
                                        evehicleType: e,
                                    });
                                }}
                            >
                                <Option value="CAR">Car</Option>
                                <Option value="TRUCK">Truck</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name={"evehicleStatus"}
                            label="Status"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input the status!",
                                },
                            ]}
                        >
                            <Select
                                value={selectedVehicle?.evehicleStatus}
                                onChange={(e) => {
                                    setSelectedVehicle({
                                        ...selectedVehicle,
                                        evehicleStatus: e,
                                    });
                                }}
                            >
                                <Option value="ACTIVE">Active</Option>
                                <Option value="INACTIVE">Inactive</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name={"typeOfGoodsTransported"}
                            label="Type of Goods"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input the type of goods!",
                                },
                            ]}
                        >
                            <Select
                                value={selectedVehicle?.typeOfGoodsTransported}
                                onChange={(e) => {
                                    setSelectedVehicle({
                                        ...selectedVehicle,
                                        typeOfGoodsTransported: e,
                                    });
                                }}
                            >
                                <Option value="GENERAL_PURPOSE">General Purpose</Option>
                                <Option value="CONSTRUCTION">Construction</Option>
                                <Option value="AGRICULTURE">Agriculture</Option>
                                <Option value="FURNITURE">Furniture</Option>
                                <Option value="ELECTRONIC">Electronic</Option>
                                <Option value="OTHER">Other</Option>
                            </Select>
                        </Form.Item>

                    </Form>
                </Modal>

                {/* modals assign */}
                <Modal
                    open={isAssignModalVisible}
                    onCancel={() => setIsAssignModalVisible(false)}
                    // title={selectedVehicle && selectedVehicle.driverUser === null ? "Assign Car to Driver" : "Unassign Car from Driver"}
                    footer={[
                        <Button onClick={() => setIsAssignModalVisible(false)}>Cancel</Button>,

                        selectedVehicle && selectedVehicle.driverUser === null && (
                            <Tooltip title="Email is required">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    onClick={() => assignVehicle()}
                                >
                                    Assign Vehicle
                                </Button>
                            </Tooltip>
                        ),
                        selectedVehicle && selectedVehicle.driverUser !== null && (
                            <Tooltip title="Email is not required">
                                <Button
                                    type="primary"
                                    danger
                                    htmlType="submit"
                                    onClick={() => {
                                        Modal.confirm({
                                            title: 'Are you sure you want to unassign?',
                                            onOk: () => unAssignVehicle(),
                                            okText: 'Yes',
                                            onCancel: () => { },
                                        });
                                    }}
                                    tooltip="Unassign vehicle"
                                >
                                    UnAssign Vehicle
                                </Button>
                            </Tooltip>
                        ),
                    ]}
                >
                    <Form
                        {...layout}
                        name="driverOrIndividualUserEmail"
                        form={form}
                        style={{ paddingTop: "35px" }}
                    >
                        <Typography.Title level={5} style={{ marginLeft: '4rem' }}>
                            {selectedVehicle && selectedVehicle.driverUser === null ? "Assign Car to Driver" : "Unassign Car from Driver"}
                        </Typography.Title>
                        {selectedVehicle && selectedVehicle.driverUser === null && (
                            <Form.Item
                                name="driverOrIndividualUserEmail"
                                label="Email"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    value={driverOrIndividualUserEmail}
                                    onChange={(e) => {
                                        setDriverOrIndividualUserEmail(e.target.value);
                                    }}
                                />
                            </Form.Item>
                        )}
                    </Form>
                </Modal>

                {/* modal view  */}
                <Modal
                    open={isViewPostModalVisible}
                    onCancel={() => {
                        setIsViewPostModalVisible(false);
                    }}
                    footer={[
                        <Button
                            onClick={() => {
                                setIsViewPostModalVisible(false);
                            }}
                        >
                            Cancel
                        </Button>,
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => {
                                setIsViewPostModalVisible(false);
                                editVehicle(selectedVehicle);
                            }}
                        >
                            Edit{" "}
                        </Button>,
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => {
                                setIsViewPostModalVisible(false);
                                setIsLocateModalVisible(true)

                            }}
                        >
                            Locate Vehicle
                        </Button>,
                    ]}
                >
                    <Form
                        {...layout}
                        name="nest-messages"
                        form={form}
                        style={{ paddingTop: "35px" }}
                    >
                        <Form.Item name={"brand"} label="Name">
                            <p style={{ margin: 0 }}>{selectedVehicle?.brand}</p>
                        </Form.Item>

                        <Form.Item name={"registrationNumber"} label="Number Plate">
                            <p style={{ margin: 0 }}>{selectedVehicle?.registrationNumber}</p>
                        </Form.Item>

                        <Form.Item name={"capacity"} label="Capacity">
                            <p style={{ margin: 0 }}>{selectedVehicle?.capacity}</p>
                        </Form.Item>
                        <Form.Item name={"color"} label="Color">
                            <p style={{ margin: 0 }}>{selectedVehicle?.color}</p>
                        </Form.Item>
                        <Form.Item name={"evehicleType"} label="Vehicle Type">
                            <p style={{ margin: 0 }}>{selectedVehicle?.evehicleType}</p>
                        </Form.Item>
                        <Form.Item name={"evehicleStatus"} label="Status">
                            <p style={{ margin: 0 }}>{selectedVehicle?.evehicleStatus}</p>
                        </Form.Item>
                        <Form.Item name={"typeOfGoodsTransported"} label="Type of Goods">
                            <p style={{ margin: 0 }}>{selectedVehicle?.typeOfGoodsTransported}</p>
                        </Form.Item>
                        {selectedVehicle?.driverUser && (
                            <Form.Item name={"driverUser"} label="Driver">
                                <p style={{ margin: 0 }}>{selectedVehicle.driverUser.firstName + " " + selectedVehicle.driverUser.lastName}</p>
                            </Form.Item>
                        )}
                    </Form>
                </Modal>

                {/* modals vehicle location */}
                <Modal
                    open={isLocateModalVisible}
                    onCancel={onLocationClose}
                    footer={[
                        <Button
                            onClick={() => {
                                setIsLocateModalVisible(false);
                            }}

                        >Cancel</Button>,
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => updateCurrentVehicleLocation()}
                        >
                            Update Location
                        </Button>,
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => getTheCurrentLocationOfTheVehicle(selectedVehicle)}
                        >
                            Get Location
                        </Button>,
                    ]}
                >
                    <Form
                        {...layout}
                        name="nest-messages"
                        form={form}
                        style={{ paddingTop: "35px" }}
                    >
                        <Form.Item
                            name={"location"}
                            label="Location"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input
                                value={location}
                                onChange={(e) => {
                                    setLocation(e.target.value);
                                }}
                                placeholder="Enter location"
                            />
                        </Form.Item>
                    </Form>
                </Modal>

                {/* main content */}
                <Table columns={tableCols} dataSource={vehicles} />
            </div>
        </>
    )
};

export default HistoryVehicleOnboarding;
