import axios from 'axios';

// Backend API URL based on environment
export const backendUrl = process.env.NODE_ENV === 'production'
    ? `${process.env.REACT_APP_BACKEND_URL}/api/v1/`
    : 'http://localhost:8081/api/v1/';

// WebSocket URL based on environment
export const webSocketUrl = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_BACKEND_URL.replace(/^http/, 'ws') + '/massitec'
    : 'ws://localhost:8081/massitec';

// Axios instance with interceptor to add Authorization header
const instance = axios.create({
  baseURL: backendUrl,
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
